<template>
  <div id="forgot-password" class=" ">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" :opacity="0.97" :z-index="2147480000"></loading>
    <span class="c-account-box__logo">
      <img class="mob-logo" :src="baseUrl + 'img/logo.png'" :alt="app_name + ' logo'" />
    </span>
    <div class="c-account-box__headline">Redefinição de Senha</div>
    <div class="c-account-box__content">
      <form class="c-form-account">
        <div class="c-form-account__row js-valid-row">
          <div class="">
            <label class="c-ui-input c-ui-input--account-login">
              <input
                class="c-ui-input__field c-ui-input__field--right-placeholder"
                type="password"
                v-model="password"
                placeholder="senha"
                tabindex="1"
              />
            </label>
          </div>
        </div>
        <div class="c-form-account__row c-form-account__row--submit">
          <div class="">
            <button class="btn clickable" type="button" @click="doReset">Redefinir Senha</button>
          </div>
        </div>
        <h1 v-if="message && message.length > 0">{{ message }}</h1>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { apiBaseUrl } from "@/services/api.js";
import util from "@/services/util";
import { setTimeout } from "timers";
import router from "@/router";
import { debounce } from "debounce";
import HeaderBuilder from "@/services/header-builder.js";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet


export default {
  name: "VerifyEmail",
  data: function() {
    return {
      isLoading: false,
      message: "",
      password: "",
      app_name: process.env.VUE_APP_NAME,
      tenant_id: process.env.VUE_APP_TENANT_ID,
      baseUrl: process.env.BASE_URL,
    };
  },
  components: {
    Loading
  },
  props: {
    target_url: String
  },
  mounted() {
    this.$root.$emit("showOnlyRouter");
  },
  beforeDestroy() {
    this.$root.$emit("showWholeApp");
  },
  methods: {
    doReset: debounce(
      function() {
        this.message = "Redefinindo sua senha...";

        let artifact = atob(
          decodeURIComponent(
            this.target_url.replace(/[-]/g, "+").replace(/[_]/g, "/")
          )
        );
        let url = new URL(artifact);
        let baseUrl = new URL(apiBaseUrl);
        if (url.hostname != baseUrl.hostname) {
          this.message = "Erro de segurança";
          
          
          
          
          return;
        }

        this.isLoading = true;
        axios
          .post(artifact, { password: this.password }, { headers: HeaderBuilder.build() })
          .then(
            response => {
              this.message = "Senha redefinida com sucesso! Redirecionando...";
              setTimeout(() => {
                router.push({ name: "login" });
              }, 4000);
            },
            error => {
              this.$notify({
                type: "error",
                title: "Erro",
                duration : 24000,
                text: util.stringifyAxiosError(error)
              });
              this.message = "Erro ao redefinir sua senha!";
            }
          )
          .finally(() => {
            this.isLoading = false;
          });
      },
      1000,
      true
    )
  }
};
</script>
